<template>
  <div class="doctor-list-view">
    <app-header icon="user-tie" title="Advogados"></app-header>

    <section>
      <div class="row mt-2 justify-content-around align-items-middle h-auto">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <div class="card">
            <div class="card-content">
              <div class="card-title">FILTROS</div>

              <div class="row d-flex">
                <div class="col-3 col-lg-3 col-md-3 col-sm-3 card-select">
                  <div class="card-select-title">Advogado</div>

                  <app-input
                    v-model="searchTerm"
                    placeholder="Nome, e-mail ou CPF/CNPJ"
                    icon="search"
                    type="is-primary"
                  >
                  </app-input>
                </div>

                <div
                  class="col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items: middle"
                >
                  <b-tooltip label="FILTROS">
                    <b-checkbox
                      v-model="checked"
                      class="mr-0"
                      label="Filtrar resultados"
                      style="margin-top: 25px"
                    />
                  </b-tooltip>

                  <span style="padding-top: 23px">OUTROS FILTROS</span>
                </div>

                <div class="col-5 col-lg-5 col-md-5 col-sm-4">
                  <b-tooltip label="FILTRAR">
                    <b-button
                      type="is-primary"
                      icon-right="search"
                      style="margin-top: 10px; margin-right: 15px"
                      @click="load()"
                      >FILTRAR</b-button
                    >
                  </b-tooltip>

                  <b-tooltip label="LIMPAR FILTROS">
                    <b-button
                      type="is-primary"
                      icon-right="recycle"
                      style="margin-top: 10px; margin-right: 15px; width: 20px"
                      @click="limpar_filtros()"
                    ></b-button>
                  </b-tooltip>

                  <b-tooltip label="NOVO ADVOGADO">
                    <router-link
                      :to="{
                        name: 'doctors.save',
                      }"
                    >
                      <b-button
                        type="is-primary"
                        icon-right="plus"
                        style="margin-top: 10px; margin-right: 15px"
                        >NOVO</b-button
                      >
                    </router-link>
                  </b-tooltip>

                  <b-tooltip label="EXPORTAR XLS">
                    <b-button
                      @click="exportDoctors()"
                      type="is-primary"
                      icon-right="file-excel"
                      :disabled="isExporting"
                      :loading="isExporting"
                      style="margin-top: 10px; margin-right: 15px"
                    >
                      EXPORTAR XLS
                    </b-button>
                  </b-tooltip>

                  <b-tooltip
                    v-if="doctor_selected_email"
                    label="REDEFINIR SENHA"
                  >
                    <b-button
                      @click="onRequestPasswordReset()"
                      type="is-primary"
                      icon-right="key"
                      style="margin-top: 10px; margin-right: 15px"
                    >
                      REDEFINIR SENHA
                    </b-button>
                  </b-tooltip>
                </div>
              </div>

              <div v-if="checked" class="row d-flex">
                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Selecione um período</div>
                  <b-field>
                    <b-select
                      @input="onPeriodFilterChange()"
                      expanded
                      name="period"
                      v-model="periodFilter"
                      placeholder="Selecionar"
                    >
                      <b-select-option
                        v-for="period of periods"
                        :key="period.id"
                        :value="period.id"
                      >
                        {{ period.name }}
                      </b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div
                  class="col-2 col-lg-2 col-md-2 col-sm-1 card-select"
                  v-if="isCustomPeriod"
                >
                  <div class="card-select-title">Selecione as datas</div>
                  <b-field>
                    <b-datepicker
                      placeholder="Escolha as datas"
                      @input="onRangeChange"
                      clearable
                      range
                    >
                    </b-datepicker>
                  </b-field>
                </div>

                <div class="col-2 card-select">
                  <div class="card-select-title">Aprovados</div>
                  <b-field>
                    <b-select
                      v-model="searchApproved"
                      placeholder="Selecione..."
                      expanded
                    >
                      <b-select-option :value="-1">Todos</b-select-option>
                      <b-select-option :value="1">Aprovado</b-select-option>
                      <b-select-option :value="0">Não Aprovado</b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Especialidade</div>
                  <b-field>
                    <b-select
                      class="select-input"
                      v-model="searchSpecialtyId"
                      placeholder="Especialidade"
                      name="searchSpecialtyId"
                    >
                      <b-select-option :value="null">Todas</b-select-option>
                      <b-select-option
                        v-for="specialty in specialties"
                        :key="specialty.id"
                        :value="specialty.id"
                      >
                        {{ specialty.name }}
                      </b-select-option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div v-if="checked" class="row d-flex">
                <div class="col-2 card-select">
                  <div class="card-select-title">Ativo/Inativo</div>
                  <b-field>
                    <b-select
                      v-model="searchActive"
                      placeholder="Selecione..."
                      expanded
                    >
                      <b-select-option :value="-1">Todos</b-select-option>
                      <b-select-option :value="1">Ativo</b-select-option>
                      <b-select-option :value="0">Inativo</b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Status Pagarme</div>
                  <b-field>
                    <b-select
                      v-model="searchPagarme"
                      placeholder="Selecione..."
                      expanded
                    >
                      <b-select-option :value="'all'">Todos</b-select-option>
                      <b-select-option :value="'active'">Ativa</b-select-option>
                      <b-select-option :value="'refused'"
                        >Recusada</b-select-option
                      >
                      <b-select-option :value="'suspended'"
                        >Suspensa</b-select-option
                      >
                      <b-select-option :value="'blocked'"
                        >Bloqueada</b-select-option
                      >
                      <b-select-option :value="'inactive'"
                        >Inativa</b-select-option
                      >
                      <b-select-option :value="'registration'"
                        >Cadastro</b-select-option
                      >
                      <b-select-option :value="'affiliation'"
                        >Afiliação</b-select-option
                      >
                    </b-select>
                  </b-field>
                </div> -->

                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Status</div>
                  <b-field>
                    <b-select
                      v-model="searchStatus"
                      placeholder="Selecione..."
                      expanded
                    >
                      <b-select-option :value="null">Todos</b-select-option>
                      <b-select-option :value="0">À verificar</b-select-option>
                      <b-select-option :value="1">Incompleto</b-select-option>
                      <b-select-option :value="2">Completo</b-select-option>
                      <b-select-option :value="3">Reprovado</b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div
                  class="col-3 col-lg-3 col-md-3 col-sm-2 card-select"
                  style="height: auto"
                >
                  <div class="card-select-title">Tipo de Atendimento</div>
                  <b-field>
                    <multiselect
                      v-model="values"
                      :options="appointment_types"
                      class="multiselect-custom"
                      :max-height="300"
                      :option-height="100"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      dysplay-label="true"
                      label="id"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                      @select="onSelect"
                      @remove="onRemove"
                    >
                    </multiselect>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <div class="card-content custom-scroll">
            <hr class="separated-table" />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
          <div class="box-primary">
            <span v-if="approved > 0"> APROVADOS: {{ ninter(approved) }} </span>
            <span v-else> APROVADOS: 0 </span>
          </div>
        </div>

        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
          <div class="box-primary">
            <span v-if="approved > 0">
              AGUARDANDO APROVAÇÃO: {{ ninter(total - approved) }}
            </span>
            <span v-else> AGUARDANDO APROVAÇÃO: {{ ninter(total) }} </span>
          </div>
        </div>

        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
          <div class="box-primary">
            <span v-if="total_online > 0">
              ONLINES: {{ ninter(total_online) }}
            </span>
            <span v-else> ONLINES: 0 </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-sm-6">
          <b-table
            id="table-doctors"
            class="table-custom"
            :loading.sync="isLoading"
            :paginated="false"
            :data="doctors_list"
            :current-page.sync="page"
            :per-page="perPage"
            :total="total"
            :pagination-simple="false"
            pagination-position="both"
            default-sort-direction="asc"
            :pagination-rounded="false"
            sort-icon="arrow-down"
            sort-icon-size="is-small"
            default-sort="row.id"
            @select="onRowClicked"
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="id" label="ID" sortable>
              {{ props.row.id }}
            </b-table-column>

            <b-table-column v-slot="props" field="name" label="NOME" sortable>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="cpf" label="CPF" sortable>
              {{
                props.row.cpf
                  | mask(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
              }}
            </b-table-column>

            <b-table-column v-slot="props" field="oab" label="OAB" sortable>
              {{ props.row.crm }} - {{ props.row.crm_state.code }}
            </b-table-column>

            <b-table-column v-slot="props" field="email" label="EMAIL" sortable>
              {{ props.row.email }}
            </b-table-column>

            <b-table-column
              width="10%"
              v-slot="props"
              field="created_at"
              label="CADASTRO"
              sortable
              centered
            >
              <div class="text-center">
                {{ props.row.created_at | date("DD/MM/YYYY") }}
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="STATUS"
              sortable
              centered
            >
              <!-- <div class="text-center">
                {{ hasPagarmeAccount(props.row.pagarme_account) }}
              </div> -->
              <div class="text-center">
                {{ getStatusLabel(props.row.account_stage) }}
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="schedules_count"
              label="ATENDIMENTOS"
              sortable
              centered
            >
              <div class="text-center">{{ props.row.schedules_count }}</div>
            </b-table-column>

            <b-table-column v-slot="props" label="APROVADO" sortable centered>
              <div class="text-center">
                <b-checkbox
                  true-value="1"
                  false-value="0"
                  v-model="props.row.approved"
                  :doctor_id="props.row.id"
                  :value="props.row.approved"
                  class="mr-4"
                  @input="
                    approveDoctor(props.row.approved, props.row.id, props.row)
                  "
                />
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="on_line"
              label="ON LINE"
              sortable
              centered
            >
              <div class="text-center">
                <b-badge
                  :variant="props.row.on_line == 1 ? 'success' : 'secondary'"
                  pill
                >
                  {{ props.row.on_line == 1 ? "Online" : "Offline " }}
                </b-badge>
              </div>
            </b-table-column>

            <b-table-column v-slot="props" label="OPÇÕES">
              <b-tooltip label="Visualizar OAB" position="is-left" class="m-1">
                <b-button
                  @click="onClickOabView(props.row)"
                  type="is-primary"
                  size="is-small"
                  icon-right="images"
                />
              </b-tooltip>

              <b-tooltip
                :label="
                  props.row.approved === 1
                    ? 'Não é possível reprovar este advogado'
                    : 'Reprovar'
                "
                position="is-left"
                class="m-1"
              >
                <b-button
                  size="is-small"
                  type="is-primary"
                  icon-right="times-circle"
                  :disabled="props.row.approved === 1"
                  @click="onRejectLawyerClick(props.row.id)"
                />
              </b-tooltip>

              <router-link
                :to="{
                  name: 'doctors.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-primary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip
                :label="
                  props.row.second_stage
                    ? 'Não é possível deletar esse advogado'
                    : 'Deletar'
                "
                position="is-left"
                class="m-1"
              >
                <b-button
                  @click="onClickDelete(props.row.id)"
                  :disabled="!!props.row.second_stage"
                  type="is-primary"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>

              <b-tooltip
                v-if="props.row.cancellation_requests.length > 0"
                label="Solicitação de cancelamento"
                class="m-1"
              >
                <b-button
                  @click="onCancellationRequestClick(props.row)"
                  type="is-danger"
                  size="is-small"
                  icon-right="exclamation-triangle"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-small"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>

            <template #footer v-if="total > 0" class="totais">
              <div class="row align-middle text-center">
                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                  Exibindo {{ rows }} de {{ total }}
                </div>

                <div class="col-auto text-center">
                  <b-pagination
                    aria-controls="table-doctors"
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="total"
                    first-number
                    last-number
                  >
                  </b-pagination>
                </div>

                <div class="col-auto text-center">
                  <b-select
                    class="pselect"
                    v-model="perPage"
                    style="margin-left: 10px"
                  >
                    <b-select-option
                      v-for="i in perPageOptions"
                      :key="i"
                      :value="i"
                    >
                      <b>{{ i }}</b>
                    </b-select-option>
                  </b-select>
                </div>

                <div class="col-auto">
                  <div class="ctab-r col-12 col-lg-12 col-md-12 col-sm-6">
                    <p>Total de advogados cadastrados: {{ total_doctors }}</p>

                    <p>Total de advogados na pesquisa: {{ total }}</p>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import * as moment from "moment";

import PERIODS from "@/constants/dashboard/periods-filter.constant";
import PAGARME_ACCOUNT_STATUS from "@/constants/pagarme-account-status.constant";
import SpecialtiesService from "@/services/specialties.service.js";
import AppointmentTypesService from "@/services/appointment_types.service.js";
import DoctorsService from "@/services/doctors.service";
import adminsService from "@/services/admins.service";
import RejectJustification from "../../modals/RejectJustification.vue";
import CancellationRequest from "../../modals/CancellationRequest.vue";

export default {
  components: { Multiselect },

  mounted() {
    this.isLoading = true;

    this.onPeriodFilterChange();

    Promise.all([
      this.loadSpecialties(),
      this.loadAppointmentTypes(),
      this.load(),
    ]).then(() => (this.isLoading = false));
  },

  data: () => ({
    isLoading: true,
    isExporting: false,
    checked: false,
    pagarme_status: PAGARME_ACCOUNT_STATUS,
    periods: PERIODS,
    jsontoexcel: jsontoexcel,
    data: [],
    page: 1,
    perPageOptions: [10, 20, 40, 80],
    perPage: 10,
    total: 0,
    total_online: 0,
    total_doctors: 0,
    dates: [],
    datesFilter: [],
    jsondata: {},
    specialties: "",
    searchSpecialtyId: "",
    searchCityId: "",
    sorter: ["read_only"],
    online: "",
    searchDate: null,
    searchDateEnd: null,
    searchTerm: null,
    searchApproved: -1,
    searchActive: -1,
    searchPagarme: null,
    periodFilter: 10,
    selectedPeriod: 10,
    approved: 0,
    doctor_selected_email: null,
    appointment_types: [],
    values: [],
    searchStatus: null,
  }),

  filters: {
    toDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD/MM/YYYY");
      }
    },
  },

  computed: {
    doctors_list() {
      return this.data;
    },

    rows() {
      return this.data.length;
    },
  },

  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),

    sorter() {
      this.page = 1;
      this.load();
    },

    searchCityId() {
      this.page = 1;
    },

    appointment_types() {
      this.page = 1;
    },

    searchPagarme() {
      this.page = 1;
    },
    searchStatus() {
      this.page = 1;
    },
    page() {
      if (this.data.length > 0 && this.data.length < this.total) this.load();
    },

    perPage() {
      this.load();
    },
  },

  methods: {
    load() {
      this.isLoading = true;

      const params = this.filters();

      DoctorsService.getcountByApproved(params).then(
        ({ data }) => (this.approved = data.approved)
      );

      DoctorsService.get(params)
        .then(({ data }) => {
          const {
            total,
            total_online,
            total_doctors,
            data: items,
            current_page,
          } = data;

          this.total = total;
          this.total_online = total_online;
          this.total_doctors = total_doctors;
          this.data = items ?? [];
          this.page = current_page;
        })
        .finally(() => (this.isLoading = false));

      for (var i = 0; i < this.data.length; i++) {
        this.jsondata[(this.data[i].id = this.data[i].value)];
        this.jsondata[(this.data[i].name = this.data[i].value)];
        this.jsondata[(this.data[i].email = this.data[i].value)];
        this.jsondata[(this.data[i].approved = this.data[i].value)];
        this.jsondata[(this.data[i].read_only = this.data[i].value)];
        this.jsondata[(this.data[i].active = this.data[i].value)];
        this.jsondata[(this.data[i].online = this.data[i].value)];
      }
    },

    loadSpecialties() {
      return SpecialtiesService.getAll().then(
        ({ data }) => (this.specialties = data)
      );
    },

    loadAppointmentTypes() {
      return AppointmentTypesService.getAll((data) => console.log(data));
    },

    limpar_filtros() {
      this.searchTerm = "";
      this.searchApproved = -1;
      this.searchActive = -1;
      this.searchPagarme = null;
      this.searchDate = null;
      this.searchDateEnd = null;
      this.online = false;
      this.sorter = false;
      this.searchSpecialtyId = null;
      this.searchCityId = null;
      this.datesFilter = [];
      this.periodFilter = 10;
      this.checked = false;
      this.onPeriodFilterChange();
      this.load();
      this.appointment_types = [];
    },

    filters() {
      this.pages =
        this.perPage < this.total ? parseInt(this.total / this.perPage) : 1;

      const params = {
        page: this.page,
        perPage: this.perPage,
        period: this.selectedPeriod,
      };

      if (this.searchTerm) {
        params[
          "filter[name_email_or_document]"
        ] = this.searchTerm.toLowerCase().trim();
      }

      if (this.searchPagarme != "all")
        params["filter[pagarme_status]"] = this.searchPagarme;

      if (this.searchApproved != -1) {
        params["filter[approved]"] =
          this.searchApproved > -1 ? this.searchApproved : "";
      }

      if (this.searchActive != -1) {
        params["filter[active]"] =
          this.searchActive > -1 ? this.searchActive : "";
      }

      if (this.appointment_types != []) {
        params["filter[appointment_types]"] =
          this.appointment_types != [] ? this.appointment_types : [];
      }

      if (this.searchDate) {
        params["filter[created_at]"] = moment(this.searchDate).format(
          "YYYY-MM-DD"
        );
      }

      if (this.searchDateEnd) {
        params["filter[created_at_less]"] = moment(this.searchDateEnd).format(
          "YYYY-MM-DD"
        );
      }

      if (this.online != "") {
        params["online"] = this.online;
      }

      if (this.sorter) {
        params["sort"] = this.sorter;
      }

      if (this.searchSpecialtyId) {
        params["filter[by_specialty]"] = this.searchSpecialtyId;
      }

      if (this.searchCityId) {
        params["filter[by_city]"] = this.searchCityId;
      }

      if (this.searchStatus >= 0)
        params["filter[account_stage]"] = this.searchStatus;

      return params;
    },

    onPeriodFilterChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);

      if (!period.custom) {
        this.page = 1;
        this.isCustomPeriod = false;

        const { start, end } = period;

        this.dates = [
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD"),
        ];

        this.selectedPeriod = `${this.dates[0]},${this.dates[1]}`;
      } else {
        this.isCustomPeriod = true;
        this.dates = [];
      }
    },

    onSelect(values) {
      values &&
        this.appointment_types.push(
          Object.values(values).toString().substring(0, 1)
        );
    },

    onRemove(values) {
      if (values) {
        const val = Object.values(values).toString().substr(0, 1);
        this.appointment_types.splice(this.appointment_types.indexOf(val), 1);
      }
    },

    onRangeChange(values) {
      this.dates = values.map((value) => moment(value).format("YYYY-MM-DD"));
      this.dates[1] = moment(this.dates[1]).add(1, "day").format("YYYY-MM-DD");

      this.page = 1;

      this.selectedPeriod = `${this.dates[0]},${this.dates[1]}`;
    },

    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Excluindo Advogado",
        message:
          "Tem certeza que deseja <b>excluir</b> este advogado? Essa ação não pode ser desfeita.",
        cancelText: "Cancelar",
        confirmText: "Excluir Advogado",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },

    onRowClicked(doctor) {
      this.doctor_selected_email = doctor.email;
    },

    onRequestPasswordReset() {
      if (!this.doctor_selected_email) return;

      adminsService
        .resetPassword(this.doctor_selected_email)
        .then(async ({ data }) => {
          let message = "Não foi possível copiar o link";
          let type = "is-warning";

          if (data?.link) {
            await window.navigator.clipboard.writeText(data.link);
            message = "Link copiado para área de transferência";
            type = "is-success";
          }

          this.$buefy.snackbar.open({
            message,
            type,
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: "Erro ao gerar link para redefinição de senha",
            type: "is-danger",
            duration: 5000,
            pauseOnHover: true,
          })
        );
    },

    ninter(value) {
      return parseInt(value);
    },
    getStatusLabel(status) {
      switch (status) {
        case 0:
          return "À verificar";
        case 1:
          return "Incompleto";
        case 2:
          return "Completo";
        case 3:
          return "Reprovado";
      }
    },
    approveDoctor(value, doctor_id, row) {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente ${
          row.approved != 1 ? "<b>desaprovar</b>" : "<b>aprovar</b>"
        } esse advogado?`,
        onCancel: () => (value == 1 ? (row.approved = 0) : (row.approved = 1)),
        onConfirm: () => {
          DoctorsService.patch(doctor_id, { approved: value })
            .then(() => {
              this.$buefy.snackbar.open("Advogado atualizado com sucesso!");
            })
            .catch(({ response }) => {
              const { message, errors } = response;

              if (response.status === 422) {
                this.errors = errors;
                value == 1 ? (row.approved = 0) : (row.approved = 1);
              } else
                this.$buefy.snackbar.open(
                  message || "Erro ao tentar a atualizar o Advogado.",
                  "error"
                );
              value == 1 ? (row.approved = 0) : (row.approved = 1);
            })
            .finally(() => (this.isSaving = false));
        },
      });
    },

    setReadOnly(tableRow, value) {
      const { row: doctor, index } = tableRow;
      DoctorsService.patch(doctor.id, { read_only: value ? 1 : 0 })
        .then(() =>
          this.$buefy.snackbar.open("Advogado atualizado com sucesso.")
        )
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao tentar atualizar advogado.");

          this.$set(this.data[index], "read_only", !value);
        });
    },

    setPayerUser(value, doctor_id, row) {
      DoctorsService.patch(doctor_id, { payer_user: value ? 1 : 0 })
        .then(() =>
          this.$buefy.snackbar.open("Advogado atualizado com sucesso.")
        )
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
            value == 1 ? (row.active = 0) : (row.active = 1);
          } else
            this.$buefy.snackbar.open(
              message || "Erro ao tentar a atualizar o Advogado.",
              "error"
            );
          value == 1 ? (row.active = 0) : (row.active = 1);
        });
    },

    activeDoctor(value, doctor_id, row) {
      DoctorsService.patch(doctor_id, { active: value })
        .then(() => {
          this.$buefy.snackbar.open("Advogado atualizado com sucesso!");
        })
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
            value == 1 ? (row.active = 0) : (row.active = 1);
          } else
            this.$buefy.snackbar.open(
              message || "Erro ao tentar a atualizar o Advogado.",
              "error"
            );
          value == 1 ? (row.active = 0) : (row.active = 1);
        })
        .finally(() => (this.isSaving = false));
    },

    hasPagarmeAccount(account) {
      if (!account) return "-";

      return this.pagarme_status.filter(
        (status) => status.key == account.status
      )[0].text;
    },

    delete(id) {
      DoctorsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open("Advogado excluído com sucesso.");
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || "Erro ao tentar excluir o Advogado.",
              "error",
              4000
            );
          }
        });
    },

    exportDoctors() {
      this.isExporting = true;

      if (this.dates.length == 2) {
        this.selectedPeriod = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchSpecialtyId == null || this.searchSpecialtyId == "") {
        this.searchSpecialtyId = "";
      }

      let filters = {
        active: this.searchActive > -1 ? this.searchActive : "",
        range: this.selectedPeriod || "",
        term: this.searchTerm || "",
        approved: this.searchApproved > -1 ? this.searchApproved : "",
        specialty_id: this.searchSpecialtyId || "",
        appointment_types: this.appointment_types,
        account_stage: this.searchStatus || "",
        online: this.online,
      };

      DoctorsService.export(filters)
        .then(({ data }) => {
          const fileName = data.split("/")[data.split("/").length - 1];
          const fileLink = document.createElement("a");

          fileLink.target = "_blank";
          fileLink.href = data;
          fileLink.setAttribute("download", fileName);
          fileLink.setAttribute("style", "display:none;");

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || "Erro ao realizar exportação.";
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
    onClickOabView(lawyer) {
      window.open(lawyer.photo, "_blank");
      window.open(lawyer.crm_photo_front, "_blank");
      window.open(lawyer.crm_photo_verse, "_blank");
    },
    onRejectLawyerClick(lawyerId) {
      this.$buefy.modal.open({
        parent: this,
        component: RejectJustification,
        hasModalCard: true,
        trapFocus: true,

        events: {
          close: (data) => data && this.rejectlawyer(lawyerId, data),
        },
      });
    },
    rejectlawyer(lawyerId, justification) {
      this.isLoading = true;

      adminsService
        .rejectLawyer(lawyerId, { justification })
        .then(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.message,
            type: "is-success",
          });
          this.load();
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: "Erro ao tentar reprovar cadastro de advogado",
            type: "is-danger",
          })
        )
        .finally(() => (this.isLoading = false));
    },
    onCancellationRequestClick(lawyer) {
      this.$buefy.modal.open({
        parent: this,
        component: CancellationRequest,
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        props: {
          cancellation_request: lawyer.cancellation_requests[0],
          lawyer_id: lawyer.id,
        },
        events: {
          close: (reload) => {
            reload && this.load();
          },
        },
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.table-custom {
  height: auto !important;

  & table tbody tr {
    &:hover {
      cursor: pointer;
      background-color: #e6e6e6;
    }
  }
}
</style>
